<template>
  <FormKit
    :id="id"
    :type="select"
    :name="name ?? id"
    :input-name="name ?? id"
    :select-options="options"
    :selected-option="value"
    :input-container-style="'relative'"
    :outer-class="'mb-sm'"
    :input-class="inputClass"
    :inner-class="{
      'pt-[5px] relative': true,
      innerClass: true,
      'partial-opacity ': disabled && !hasWhiteBg,
      'partial-opacity disabledSelect': disabled && hasWhiteBg,
    }"
    :validation-visibility="validationVisibility"
    :validation-messages="{
      required: t(requiredMessage),
      matches: t(matchesMessage),
    }"
    :validation="required ? 'required' : ''"
    :disabled="disabled"
    :is-loading="isLoading"
    :searchable="false"
    :placeholder="placeholder ? t(placeholder) : undefined"
    :testid="testid.length > 0 ? testid : ''"
    @input="update"
  >
    <template #label>
      <FormLabel
        :label="label"
        :required="required"
        :hide-suffix="hideLabelSuffix"
      />
    </template>
    <template v-if="info || $slots.info" #suffix>
      <Popup
        :trigger-class="`${forceInfo ? 'force-info' : ''} top-[14px] right-[44px]`"
        :content-class="forceInfo ? 'force-info' : ''"
        :disabled="disabled && !forceInfo"
        :info-on-click="infoOnClick"
      >
        <template #trigger>
          <FaIcon
            icon-class="fad fa-circle-info"
            style="
              --fa-primary-color: var(--thm-text-base);
              --fa-secondary-color: var(--thm-status-warn-base);
              --fa-secondary-opacity: 1;
            "
          />
        </template>
        <template #content>
          <slot name="info">
            {{ t(info) }}
          </slot>
        </template>
      </Popup>
    </template>
  </FormKit>
</template>
<script setup lang="ts">
import { createInput, FormKit } from '@formkit/vue';
import selectField from '~~/src/components/formFields/components/customSelects/customSelect.vue';
import Popup from '~~/src/components/formFields/components/formInfoPopup.vue';
import FaIcon from '~~/src/components/fa-icon.vue';
import { FormLabel } from '@/complib';
import type { FormkitFieldProps } from '~/@types/formie';
import type { DropdownOption as Option } from '.';

interface SelectFieldProps extends FormkitFieldProps {
  options: Option[];
  selectedOption?: string;
  customSelect?: object;
  allowEmpty?: boolean;
  isLoading?: boolean;
  forceInfo?: boolean;
  infoOnClick?: boolean;
  /**
   * @workaround
   * this should only accept a string, but beacause of fucking formkit it needs to accept Option too, because of Bug #18009
   * this will be gone when we refactor th forms and kill formkit, so when you use the dropdown manually,
   * DO NOT supply the dropen with an Option object, only use strings. only formkit is allowed to use the Option here, if you do it it would be like parking in an handicapped parking space
   */
  modelValue: string | null | Option;
}

const props = withDefaults(defineProps<SelectFieldProps>(), {
  label: '',
  id: '',
  required: true,
  placeholder: undefined,
  selectedOption: null,
  disabled: false,
  customSelect: () => selectField,
  hasWhiteBg: true,
  allowEmpty: false,
  validationVisibility: 'blur',
  isLoading: false,
  info: '',
  modelValue: '',
  hideLabelSuffix: false,
  forceInfo: false,
  infoOnClick: false,
  testid: '',
});

/**
 * !! WARNING !!
 * dropdown reactivity is fickle so DONT touch this if you are not 100% percent sure why this works
 */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const value = ref<Option>(
  getSelectedOption(
    typeof props.modelValue === 'string'
      ? props.modelValue
      : props.modelValue?.value,
  ),
);

watch(
  () => props.modelValue,
  (nv) => {
    value.value = getSelectedOption(typeof nv === 'string' ? nv : nv?.value);
  },
);

function update(n: Option) {
  //checke for string, because when initializing formkit will call this with a string, which will null things down the line
  if (typeof n !== 'string') emit('update:modelValue', n?.value);
}

function getSelectedOption(value: string) {
  const option = props.options.find((option) => option?.value === value);
  if (option) return option;
  return props.allowEmpty ? null : props.options[0];
}

const { t } = useTrans();
const select = createInput(props.customSelect, {
  props: [
    'selectOptions',
    'inputContainerStyle',
    'id',
    'value',
    'isLoading',
    'customPlaceholder',
    'selected',
    'selectedOption',
    'validationVisibility',
    'testid',
  ],
});
</script>
<style lang="postcss">
.partial-opacity {
  > div {
    @apply opacity-50;

    &.force-info {
      @apply opacity-100;
    }
  }
}

.disabledSelect {
  .vs--disabled {
    @apply !bg-background-base;
    @apply cursor-no-drop;
    .vs__dropdown-toggle {
      background-color: transparent !important;
      * {
        background-color: transparent !important;
        @apply cursor-no-drop;
      }
    }
  }
}

.formkit-outer:has(> .formkit-messages) .v-select:not(.vs--open) {
  @apply !border-b-status-danger-base !rounded-b-none;
}

.vs__dropdown-menu {
  @apply mt-[1px];
}
</style>
